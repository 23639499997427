import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { DEFAULT_LOCALE, ROOT_SLUG } from "constants/index";
import { enGB, nl } from "date-fns/locale";
import { Locales } from "./types";
import { Duration } from "date-fns";
import dateFnsFormatDuration from "date-fns/formatDuration";
import dateFnsFormatDistance from "date-fns/formatDistance";

export const localeDateFnsMap: Record<Locales, Locale> = {
	en: enGB,
	nl: nl,
};

export const getLocale = (): Locales => {
	// Only accept en or nl
	const paramsLanguage =
		(new URLSearchParams(window.location.search).get("lang") as Locales) ||
		(localStorage.getItem("locale") as Locales);

	if (paramsLanguage === "en" || paramsLanguage === "nl") {
		return paramsLanguage;
	}
	// If the language is not supported, return the default language
	return getDefaultLocale();
};

export const updateLocale = (locale: Locales) => {
	localStorage.setItem("locale", locale);
};

export const getDefaultLocale = () => {
	const storedLocale = localStorage.getItem("locale") as Locales;
	const browserSetUpLanguage = navigator.language || DEFAULT_LOCALE;
	const localeLanguage = browserSetUpLanguage.includes("nl") ? "nl" : DEFAULT_LOCALE;
	return storedLocale || localeLanguage;
};

export const useLoginContent = ({ contentId, orgSlug = ROOT_SLUG.toUpperCase() }) => {
	const intl = useIntl();
	const defaultId = "AUTH.default.header";
	const defaultMessage = intl.formatMessage({ id: defaultId }, { company: orgSlug?.toUpperCase() });
	const [content, setContent] = useState(defaultMessage);

	useEffect(() => {
		setContent(intl.formatMessage({ id: defaultId }, { company: orgSlug?.toUpperCase() }));
	}, [intl, orgSlug]);

	return contentId ? intl.formatMessage({ id: contentId, defaultMessage: content }) : defaultMessage;
};

export const useTipsContent = ({ contentId, fallbackMessage }: { contentId: string; fallbackMessage?: string }) => {
	const defaultId = "AUTH.default.tips";
	const intl = useIntl();
	const defaultMessage = fallbackMessage || intl.formatMessage({ id: defaultId });
	return contentId ? intl.formatMessage({ id: contentId, defaultMessage: defaultMessage }) : defaultMessage;
};

export function formatDuration(duration: Duration) {
	return dateFnsFormatDuration(duration, {
		locale: localeDateFnsMap[getLocale()],
	});
}

export function formatDistance(date: Date, baseDate: Date, options?: { addSuffix: boolean }) {
	return dateFnsFormatDistance(date, baseDate, {
		locale: localeDateFnsMap[getLocale()],
		addSuffix: options?.addSuffix,
	});
}
